<template>
  <div id="app">
    <img 
      alt="Lightkey logo"
      src="./assets/lightkey_logo.png"
      width="200"
      height="175"
    >
    <img 
      alt="Lightkey logo"
      src="./assets/lightkey.png"
      width="150"
      height="38"
      style="margin-bottom:50px;"
    >
    <ForgotPassword/>
  </div>
</template>

<script>
import ForgotPassword from './components/ForgotPass.vue'

export default {
  name: 'App',
  components: {
    ForgotPassword
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body{
 background-color: white;
}
</style>
