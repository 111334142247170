<template>
   <div class="wrapper">
    <section class="grid-content">
      <div class="contenedor">
        <form @submit.prevent="updateUser()" v-if="!this.isUpdated">
          <div class="form-input-data">
            <section class="form-input-data__content">
              <div >
                <label for="inputNewPassword">Nueva contraseña:</label>
                <input
                  v-model="userForUpdate.newpassword"
                  v-autowidth="{maxWidth: '960px', minWidth: '200px', comfortZone: 0}"
                  type="password"
                  class="form-control form-control__input-data__newpass"
                  id="inputNewPassword"
                  placeholder="Introduzca su nueva contraseña"
                  @change="checkPasswordLength()">
              </div>
              <div class="form-input-data__newpass">
                <label for="inputNewPassword">Confirme su contraseña:</label>
                <input
                  v-model="userForUpdate.confirmnewpassword"
                  v-autowidth="{maxWidth: '960px', minWidth: '200px', comfortZone: 0}"
                  type="password"
                  class="form-control"
                  id="inputNewPassword"
                  placeholder="Confirma tu nueva contraseña"
                  @change="checkPasswordEquals()">
              </div>
              <small v-if="isErrorPass" class="error">La nueva contraseña y la confirmación no son iguales</small>
              <small v-if="passwordNotValid" class="error">La contraseña debe tener mas de 6 caracteres alfanuméricos</small>
              <small v-if="isCheckedPass" class="correct">La nueva contraseña y la confirmación son iguales</small>
            </section>
          </div>
          <div class="form-input-data__newpass">
            <button type="submit" class="btn btn-primary">Actualizar Contraseña</button>
          </div>
        </form>
        <p v-if="this.isUpdated">¡Su contraseña ha sido modificada con éxito!</p>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'forgot-password',
  data () {
    return {
      isErrorLogin: false,
      isUpdated: false,
      isErrorPass: false,
      isCheckedPass: false,
      passwordNotValid: false,
      userForUpdate: {
        email: '',
        token: '',
        newpassword: '',
        confirmnewpassword: ''
      }
    }
  },
  methods: {
    checkPasswordEquals () {
      if (this.userForUpdate.newpassword === this.userForUpdate.confirmnewpassword) {
        this.isCheckedPass = true
        this.isErrorPass = false
      } else {
        this.isCheckedPass = false
        this.isErrorPass = true
      }
    },
    checkPasswordLength () {
      if (this.userForUpdate.newpassword.length < 6) {
        this.passwordNotValid = true
        return
      }
      this.passwordNotValid = false
    },
    updateUser () {
      this.axios.put('https://lightkey.es:3000/lightkey/api/users/update-password', {data: this.userForUpdate})
        .then(
          response => {
            console.log(response)
            this.isUpdated = true;
            this.$toastr.s('Contraseña reestablecida')
          }
        )
        .catch(
          error => {
            console.log(error.response)
            this.$toastr.e('No se ha podido reestablecer su contraseña, intentelo de nuevo')
          }
        )
    }
  },
  mounted () {
    const urlParams = new URLSearchParams(window.location.search);
    this.userForUpdate.email = urlParams.get('email')
    this.userForUpdate.token = urlParams.get('token')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contenedor {
  margin-top: 10%;
  padding: 10% 30px;
  border: 2px solid rgb(129, 202,64);
  border-radius: 20px;
}

::-ms-reveal {
  filter: invert(100%);
}

.form-control {
  border: 1px solid rgb(195, 202, 209);
  border-radius: 3px;
  min-width:1px;
  margin-left: 5px;
}
.logo {
  margin-right: 38px;
  padding: 40px;
}

.logo-img {
  max-width: 200px;
}

.logo-text {
  max-width: 150px;
}

h1 {
  color: #7ab648;
}

input[type='password'] {
    text-align: center;
    color: rgb(98, 98, 98);
}

.btn {
  width: 200px;
  height: 40px;
  background-color: rgb(129, 202,64);
  color: white;
  border: 1px solid rgb(129, 202,64);
  border-radius: 7px;
}

.btn:hover {
  background-color: #5cb85c;
  color: white;
  border: 1px solid #4cae4c;
}

.form-control__input-data__newpass{
  margin-left: 40px;
}

.wrapper {
  /* padding: 50px auto; */
  display: grid;
  grid-template-columns: 1fr minmax(200px, 700px) 1fr;
}
.form-input-data{
  display: grid;
}
@media only screen and (max-width: 430px) {
  .wrapper {
    /* padding: 50px auto; */
    display: grid;
    grid-template-columns: 1fr;
  }
  .form-input-data__newpass{
      margin-top: 0%;
  }
  .form-control__input-data__newpass{
    margin-left: 0px;
  }
}

.form-input-data__newpass{
    margin-top: 5%;
}
.grid-content {
  grid-column: 2/3;
}

.error {
  color: red;
}

.correct {
  color: green;
}
</style>
